<style>
  .requestType {
    display: flex;
    align-items: center;
  }

  .requestType-icon {
    display: inline-block;

    width: 10px;
    height: 10px;
  }

  .burn {
    transform: rotateZ(180deg);
  }
</style>

<script>
  import Label from '../Atoms/Label.svelte';

  export let type;
  const burn = type === 'burn';
</script>

<div class="requestType">
  <svg
    fill="none"
    class:burn
    class="requestType-icon"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2506 3.35858L4.23885 3.07081C3.93523 3.05628 3.71841 3.2731 3.73294 3.57672C3.74748 3.88035 3.9861 4.11896 4.28972 4.1335L9.02626 4.36023L3.51908 9.8674C3.31672 10.0698 3.33271 10.4038 3.55542 10.6265C3.77813 10.8492 4.11212 10.8652 4.31448 10.6628L9.83611 5.14117L10.031 9.84589C10.0456 10.1495 10.2842 10.3881 10.5878 10.4027C10.7396 10.4099 10.8582 10.355 10.9449 10.2683C11.0316 10.1816 11.101 10.0486 11.0793 9.91122L10.7929 3.92981C10.7857 3.778 10.7191 3.65364 10.6237 3.5582C10.5109 3.41648 10.3721 3.3644 10.2506 3.35858Z"
      fill="#404458"
    />
  </svg>
  &nbsp;
  <Label capitalize>{type}</Label>
</div>
