<style>
  .button {
    padding: 10px 28px;

    background-color: #ffffff;
    border: none;

    border-radius: 4px;

    color: #2744f7;
    cursor: pointer;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 20px;

    transition: all 0.2s ease-in-out;
  }

  .button:hover {
    transform: scale(1.1);
  }
</style>

<button class="button" on:click {...$$restProps}>
  <slot>Subscribe</slot>
</button>
