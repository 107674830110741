<style>
  .link {
    color: #ffffff;

    cursor: pointer;

    opacity: 0.5;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;

    transition: all 0.2s ease-in-out;
  }

  .link.is-active,
  .link:hover {
    opacity: 1;
  }

  .link:not(:last-child) {
    margin-right: 22px;
  }
</style>

<script>
  export let href = null;
  export let label = null;
  export let isActive = false;
  export let target = "_blank";
</script>

<a class="link" class:is-active={isActive} {href} {target} on:click>
  <slot>{label}</slot>
</a>
