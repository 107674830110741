<style>
  .home {
    position: relative;

    padding: 28px;

    /* overflow: hidden; */
  }

  .home-background {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .home-badge {
    display: none;
  }

  .home-navbar {
    grid-column: 1 / 6;
  }

  .home-footer {
    grid-column: 1 / 6;

    margin-top: 100px;
  }

  .home-content {
    position: relative;
  }

  .home-dashboard {
    grid-column: 1 / 6;
    margin-top: 50px;
  }

  .home-banner {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    transform: translateY(-30px);
  }

  .home-copy {
    grid-column: 1 / 6;
    margin-top: 50px;
  }

  .home-assets {
    grid-column: 1 / 6;
    margin-top: 80px;
  }

  @media (min-width: 1280px) {
    .home {
      padding: 0;
    }

    .home-badge {
      position: absolute;

      top: 435px;
      right: 0%;

      display: block;
    }

    .home-content {
      max-width: 1392px;

      padding: 60px 0px 170px;
      margin: 0 auto;
    }

    .home-navbar {
      grid-column: 3/ 19;
      margin-bottom: 100px;
    }

    .home-footer {
      grid-column: 3/ 19;
    }

    .home-copy {
      grid-column: 3 / 12;
    }

    .home-dashboard {
      position: relative;

      grid-column: 6 / span 10;
      margin-top: 56px;
      margin-bottom: 64px;
    }

    .home-assets {
      grid-column: 3 / 19;
      margin-top: 120px;
    }
  }
</style>

<script>
  import {
    totalsStore,
    tokensStore,
    requestsStore,
  } from '../../api/index.svelte';

  import Grid from '../Atoms/Grid.svelte';
  import Link from '../Atoms/Link.svelte';
  import Text from '../Atoms/Text.svelte';
  import Title from '../Atoms/Title.svelte';
  import Background from '../Atoms/Background.svelte';
  import CertikBadge from '../Atoms/CertikBadge.svelte';

  import Assets from '../Molecules/Assets.svelte';
  import Navbar from '../Molecules/Navbar.svelte';
  import Footer from '../Molecules/Footer.svelte';
  import Dashboard from '../Molecules/Dashboard.svelte';
  import CertikBanner from '../Molecules/CertikBanner.svelte';

  let tokens = [];
  let totals = {};
  let requests = [];

  tokensStore.subscribe((value) => (tokens = value));
  totalsStore.subscribe((value) => (totals = value));
  requestsStore.subscribe((value) => (requests = value));
  $: console.log(totals);
</script>

<main class="home">
  <div class="home-background">
    <Background />
  </div>
  <div class="home-badge">
    <CertikBadge />
  </div>
  <div class="home-content">
    <Grid>
      <nav class="home-navbar">
        <Navbar />
      </nav>

      <div class="home-dashboard">
        <Dashboard {totals} />
        <div class="home-banner">
          <CertikBanner latestCertification={totals.latestCertification} />
        </div>
      </div>

      <div class="home-copy">
        <Title>Crystal clear</Title>
        <br />
        <Text>
          We regularly provide all the information you need, so you know that
          your digital assets are always fully backed, with maximum security and
          transparency.
        </Text>
        <br />
        <Text>
          All the data is verified by
          <Link href="https://certik.io/">CertiK</Link>, the most
          advanced formal verification technology for smart contracts and
          blockchain.
        </Text>
      </div>

      <div class="home-assets">
        {#if tokens.length}
          <Assets {tokens} {totals} selectedToken={tokens[0]} {requests} />
        {/if}
      </div>

      <div class="home-footer">
        <Footer />
      </div>
    </Grid>
  </div>
</main>
