<style>
  .dropdown {
    display: inline-block;

    background: #ffffff;

    border: 1px solid #e1e1e1;
    border-bottom: none;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0px 0px 100px rgba(39, 68, 247, 0.05);
  }

  .dropdown-arrow {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }

  .dropdown-header {
    display: flex;
  }

  .dropdown-title {
    display: none;
  }

  .dropdown-search {
    padding: 16px 28px 20px;

    border: 1px solid #e1e1e1;
  }

  .dropdown-list .dropdown-search input {
    position: static;

    visibility: visible;
    width: 100%;

    padding: 8px 12px;

    border: 1px solid #e6e6e6;
    border-radius: 5px;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 28px;
  }

  .dropdown-columnTitles {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(8, minmax(auto, 1fr));
    padding: 10px 28px;

    border: 1px solid #e1e1e1;
  }

  .dropdown-list {
    position: fixed;
    top: 90px;
    right: 28px;
    left: 28px;
    z-index: 1;

    background: #ffffff;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  .dropdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;

    display: none;

    width: 100vw;
    height: 100vh;

    background: #091135;
    opacity: 0;

    animation: show 0.3s ease-out;
    animation-fill-mode: forwards;
    animation-direction: reverse;
  }

  .dropdown-overlay.open {
    display: block;

    animation: show 0.5s ease-out;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }

  .dropdown-option {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(8, minmax(auto, 1fr));
    padding: 16px 28px 20px;

    border: 1px solid #e1e1e1;

    cursor: pointer;

    text-align: left;
  }

  .dropdown-list input {
    position: absolute;

    visibility: hidden;
  }

  .dropdown-option:hover {
    background: #f9f9ff;
  }

  .dropdown-selectedTokenLogo {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }

  .dropdown-tokenLogo {
    display: none;
  }

  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;

    background: #eef0fe;

    background: #eef0fe;
    border: 1px solid #2744f7;
    border-radius: 4px;
    box-shadow: 0px 0px 100px rgba(39, 68, 247, 0.1);

    color: #2744f7;

    cursor: pointer;

    font-family: 'Averta Std';
    font-size: 20px;
    line-height: 28px;
  }

  .dropdown-radio {
    box-sizing: border-box;
    width: 15px;
    height: 15px;
    margin-right: 3px;

    background: none;
    border: 1px solid #646464;
    border-radius: 50%;
  }

  .dropdown-radio.checked {
    background: #2744f7;
    border: none;
    box-shadow: 0px 0px 0px 3px rgba(39, 68, 247, 0.1);
  }

  .dropdown-close {
    position: fixed;
    top: 30px;
    left: 50%;

    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 12px;

    background: #2744f7;

    border: none;

    transform: translateX(-50%);
  }

  .dropdown-emptyState {
    padding: 16px;

    border: 1px solid #e1e1e1;

    text-align: center;
  }

  @media (min-width: 1280px) {
    .dropdown {
      position: relative;

      text-align: left;
    }

    .dropdown-title {
      display: flex;
      align-items: center;
      padding: 23px 28px 30px;
    }

    .dropdown-button {
      padding: 23px 28px 30px;

      border: none;
      box-shadow: none;

      font-size: 28px;
      line-height: 39px;
    }

    .dropdown-header {
      position: relative;
      z-index: 1;

      background: #ffffff;
    }

    .dropdown-list {
      position: absolute;
      top: 100%;
      left: 0;

      width: 100%;

      background: #ffffff;
    }

    .dropdown-radio {
      margin-right: 20px;
    }

    .dropdown-tokenLogo {
      align-self: center;
      display: flex;

      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .dropdown-close {
      display: none;
    }
  }
</style>

<script>
  import accounting from 'accounting';
  import { writable } from 'svelte/store';
  import { fade } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';

  import Text from '../Atoms/Text.svelte';
  import Label from '../Atoms/Label.svelte';
  import Arrow from '../Atoms/Arrow.svelte';
  import Number from '../Atoms/Number.svelte';
  import CoinLogo from '../Atoms/CoinLogo.svelte';

  export let tokens;
  export let selectedToken;
  export let setSelectedToken;

  const store = writable(false);

  export let open;
  store.subscribe((value) => (open = value));

  $: search = '';
  $: filteredTokens = tokens.filter(({ name }) =>
    name.toLowerCase().includes(search.toLowerCase())
  );

  const toggleList = () => {
    store.set(!open);
    search = '';
  };

  const onChange = (event) => {
    setSelectedToken(filteredTokens[event.target.value]);
    toggleList();
  };

  const onSubmit = (event) => event.preventDefault();
</script>

<div class="dropdown">
  <div class="dropdown-header">
    <div class="dropdown-title">
      <Text>Current filtering by:</Text>
    </div>
    <div>
      <button class="dropdown-button" type="button" on:click={toggleList}>
        <div class="dropdown-selectedTokenLogo">
          <CoinLogo coin={selectedToken.name} />
        </div>
        {selectedToken.name}
        <div class="dropdown-arrow">
          <Arrow up={open} alternate />
        </div>
      </button>
    </div>
  </div>

  <div
    class="dropdown-overlay"
    class:open
    transition:fade={{ duration: 500, easing: quintOut }}
  />
  {#if open}
    <button
      type="button"
      class="dropdown-close"
      on:click={toggleList}
      transition:fade={{ duration: 500, easing: quintOut }}
    >
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.87921 9.49609L1.61627 15.7588L0 14.1426L6.263 7.87891L0 1.61621L1.61627 0L7.87921 6.2627L14.1422 0L15.7584 1.61621L9.49542 7.87891L15.7584 14.1426L14.1422 15.7588L7.87921 9.49609Z"
          fill="white"
        />
      </svg>
    </button>
    <form
      class="dropdown-list"
      on:submit|preventDefault
      transition:fade={{ duration: 500, easing: quintOut }}
    >
      <div class="dropdown-search">
        <input
          name="search"
          type="text"
          bind:value={search}
          placeholder="Search by currency name"
        />
      </div>
      <div class="dropdown-columnTitles">
        <div style="grid-column: span 3">
          <Label uppercase>Currency</Label>
        </div>
        <div style="grid-column: 4 / span 4">
          <Label uppercase>In circulation</Label>
        </div>
        <div>
          <Label uppercase>Covered</Label>
        </div>
      </div>
      {#each filteredTokens as token, index}
        <div>
          <input
            class="dropdown-input"
            type="radio"
            id={token.name}
            name="token"
            value={index}
            checked={selectedToken.name === token.name}
            on:change={onChange}
          />
          <label for={token.name} class="dropdown-option">
            <div
              style="grid-column: span 3; display: flex; align-items: center;"
            >
              <div
                class="dropdown-radio"
                class:checked={selectedToken.name === token.name}
              />
              &nbsp;
              <div class="dropdown-tokenLogo">
                <CoinLogo coin={token.name.toLowerCase()} />
              </div>
              <Label alternate>{token.name}</Label>
            </div>
            <div style="grid-column: 4 / span 4">
              <Number small symbol={token.name}>
                {accounting
                  .format(token.latestState.supply, 8)
                  .replace(/\.?0+$/, '')}
              </Number>
            </div>
            <div style="min-width: 62px">
              <Label alternate>
                {accounting.format(token.latestState.reservePercentage)}%
              </Label>
            </div>
          </label>
        </div>
      {/each}
      {#if !filteredTokens.length}
        <div class="dropdown-emptyState">
          <Label alternate>No results found :(</Label>
        </div>
      {/if}
    </form>
  {/if}
</div>
