<script context="module">
  import ApolloClient from 'apollo-boost';
  import { writable } from 'svelte/store';

  import { TOTALS, TOKENS, REQUESTS } from './queries';

  const URL_ENDPOINT = process.env.API_URL + '/graphql';

  const client = new ApolloClient({ uri: URL_ENDPOINT });

  export const EVENTS = {
    MintRequestCreated: 'Request Created',
    MintRequestFulfilled: 'Request Fulfilled',
    MintRequestCancelled: 'Request Cancelled',
    MintRequestRejected: 'Request Rejected',

    BurnRequestCreated: 'Request Created',
    BurnRequestFulfilled: 'Request Fulfilled',
    BurnRequestCancelled: 'Request Cancelled',
    BurnRequestRejected: 'Request Rejected',

    MintCertificationPassed: 'Certified',
    MintCertificationFailed: 'Certification Failed',

    BurnCertificationPassed: 'Certified',
    BurnCertificationFailed: 'Certification Failed',
  };

  export const tokensStore = writable([]);
  export const requestsStore = writable([]);
  export const totalsStore = writable({ usd: '', reservePercentage: '' });

  const onTokens = ({ data }) => tokensStore.set(data.tokens);

  const onTotals = ({ data }) => totalsStore.set(data.total);

  const onRequests = ({ data: { requests } }) => {
    requestsStore.set(
      requests.map(({ events, ...rest }) => ({
        ...rest,
        events: events.sort((a, b) => b.timestamp - a.timestamp),
      }))
    );
  };

  const onError = (error) => console.error(error);

  export const queryRequests = (token) => {
    client
      .query({ query: REQUESTS, variables: { token } })
      .then(onRequests)
      .catch(onError);
  };

  client.query({ query: TOKENS }).then(onTokens).catch(onError);
  client.query({ query: TOTALS }).then(onTotals).catch(onError);
</script>
