<style>
  .number,
  .symbol {
    display: inline-block;
    margin: 0;

    color: #091135;

    font-family: 'Averta Std';
    font-size: 28px;
    line-height: 40px;
  }

  .positive,
  .positive .symbol {
    color: #42d16d;
  }

  .symbol {
    font-size: 12px;
    line-height: 0;
    text-transform: uppercase;
  }

  .small {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .small .symbol {
    font-size: 12px;
  }

  .big {
    font-size: 40px;
    line-height: 72px;
  }

  .big .symbol {
    font-size: 20px;
  }

  .positive {
    font-weight: bold;
  }

  @media (min-width: 1280px) {
    .number {
      font-size: 28px;
      line-height: 28px;
    }

    .small {
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }

    .symbol {
      font-size: 16px;
      line-height: 28px;
    }

    .big {
      font-size: 48px;
      line-height: 72px;
    }
  }
</style>

<script>
  export let big = false;
  export let small = false;
  export let positive = false;
  export let symbol = '';
</script>

<p class="number" class:big class:small class:positive>
  <slot>7,963,781.593</slot>
  <span class="symbol">{symbol}</span>
</p>
