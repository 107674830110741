<style>
  .label {
    margin: 0;

    color: #646464;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 28px;
  }

  .uppercase {
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .alternate {
    color: #404458;
  }
</style>

<script>
  export let alternate = false;
  export let uppercase = false;
  export let capitalize = false;
</script>

<p class="label" class:uppercase class:capitalize class:alternate>
  <slot>Covered in reserve</slot>
</p>
