<style>
  .arrow {
    display: inline-block;

    width: 12px;
    height: 12px;

    transition: transform 0.3s ease-out;
  }

  .arrow path {
    fill: #091135;
  }

  .up {
    transform: rotateZ(180deg);
  }

  .alternate path {
    fill: #2744f7;
  }
</style>

<script>
  export let up = false;
  export let alternate = false;
</script>

<svg
  class:up
  class:alternate
  class="arrow"
  fill="none"
  viewBox="0 0 12 9"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M6 9L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 9Z" fill="" />
</svg>
