import gql from 'graphql-tag';

export const TOKENS = gql`
  query {
    tokens {
      name
      latestState {
        supply
        supplyUsd
        reservePercentage
      }
      contractAddress
      latestCertification
    }
  }
`;

export const TOTALS = gql`
  query {
    total {
      usd
      reservePercentage
      latestCertification {
        tokenName
        timestamp
      }
    }
  }
`;

export const REQUESTS = gql`
  query Request($token: String!) {
    requests(token: $token) {
      requestId
      type
      merchant {
        name
      }
      tokenName
      events {
        name
        timestamp
        txHash
      }
      stateAfterFulfill {
        name
        supply
        reserve
        reservePercentage
      }
      requestDetails {
        amount
      }
    }
  }
`;
