<style>
  .banner {
    text-align: center;
  }

  .banner-logo {
    display: inline-block;
    box-sizing: border-box;
    height: 60px;
    padding: 15px 28px;

    background: #091135;
    border-radius: 1000px;
  }

  .banner-shield {
    display: none;

    width: 28px;
    height: 28px;
  }

  .banner-message {
    display: block;
    padding: 0 20px;
    margin-top: 20px;

    color: #666666;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 20px;
  }

  .alternate .banner-logo {
    display: none;
  }

  .alternate .banner-message {
    padding: 0;
  }

  .banner-link {
    margin-top: 20px;
  }

  @media (min-width: 1280px) {
    .banner {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .alternate .banner-shield {
      position: relative;
      z-index: 1;

      display: block;
      padding: 16px;

      background: #091135;
      border-radius: 50%;
    }

    .alternate .banner-logo {
      display: none;
    }

    .banner-logo {
      position: relative;
      z-index: 1;

      height: 60px;
    }

    .banner-link {
      display: block;
      margin-top: 0;
      margin-left: 28px;
    }

    .banner-message {
      position: relative;

      display: inline-flex;
      align-items: center;
      padding: 16px 28px;
      padding-left: 16px;
      margin: 0;

      background: #404458;
      border-radius: 1000px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;

      color: #ffffff;
    }

    .alternate .banner-message {
      padding: 15px 28px;
      padding-left: 12px;
    }

    .banner-message:before {
      content: '';

      position: absolute;
      top: 0;
      left: -100px;
      z-index: 0;

      width: 100px;
      height: 100%;

      background: #404458;
    }

    .alternate .banner-message:before {
      left: -30px;

      width: 30px;
    }
  }
</style>

<script>
  import moment from 'moment';

  import Link from '../Atoms/Link.svelte';
  import CertikLogo from '../Atoms/CertikLogo.svelte';

  export let tokenContract;
  export let alternate = false;
  export let latestCertification = {};

  $: certificationTime = moment(latestCertification.timestamp, 'X').fromNow();
</script>

<div class="banner" class:alternate>
  <div class="banner-logo">
    <CertikLogo />
  </div>
  <div class="banner-shield">
    <svg viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M25.22 3.33L14.24.03a.82.82 0 00-.48 0L2.78 3.33a.82.82 0 00-.58.79V16.2c0 1.57.63 3.18 1.89 4.78a19.55 19.55 0 003.94 3.65 35.36 35.36 0 005.63 3.3.82.82 0 00.68 0c.11-.05 2.85-1.29 5.63-3.3a19.55 19.55 0 003.94-3.65c1.26-1.6 1.9-3.21 1.9-4.78V4.12a.82.82 0 00-.6-.79z"
          fill="#fff"
        />
        <path
          d="M25.22 3.33L14.24.03A.82.82 0 0014 0v28c.11 0 .23-.02.34-.07.11-.05 2.85-1.29 5.63-3.3a19.55 19.55 0 003.94-3.65c1.26-1.6 1.9-3.21 1.9-4.78V4.12a.82.82 0 00-.6-.79z"
          fill="#E4E4E4"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
  <div class="banner-message">
    {#if alternate}
      <slot>Certified {certificationTime} by CertiK</slot>
    {:else}
      <slot>
        Last certification:
        {latestCertification.tokenName}
        was certified
        {certificationTime}
      </slot>
    {/if}

    {#if alternate}
      <div class="banner-link">
        <Link light href={tokenContract}>View token contract</Link>
      </div>
    {/if}
  </div>
</div>
