<style>
  .dashboard {
    position: relative;

    padding: 16px 20px 60px;

    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 100px rgba(39, 68, 247, 0.1);
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .reserve {
    display: flex;
    align-items: center;
  }

  .shield {
    width: 28px;
    height: 28px;
  }

  .border {
    position: relative;
    left: -20px;

    width: calc(100% + 40px);
    height: 1px;
    margin-bottom: 12px;

    background: #e6e6e6;
  }

  @media (min-width: 1280px) {
    .dashboard {
      display: flex;
      justify-content: space-between;
      padding: 25px 28px 60px;
    }

    .label {
      justify-content: flex-start;
    }

    .border {
      display: none;
    }

    .shield {
      width: 40px;
      height: 40px;
    }
  }
</style>

<script>
  import accounting from 'accounting';

  import Bold from '../Atoms/Bold.svelte';
  import Label from '../Atoms/Label.svelte';
  import Number from '../Atoms/Number.svelte';
  import Information from '../Atoms/Information.svelte';

  export let totals = {
    usd: '7,963,781.593',
  };
</script>

<div class="dashboard">
  <div>
    <div class="label">
      <Label>Covered in reserve</Label>
      <Information>
        <Label alternate>
          The percentage of the total amount in circulation covered by our
          reserve.
          <Bold small>Certified by CertiK.</Bold>
        </Label>
      </Information>
    </div>

    <div class="reserve">
      <Number positive big>
        {accounting.format(totals.reservePercentage)}%
      </Number>

      <svg
        class="shield"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M36.03 4.76L20.33.05a1.17 1.17 0 00-.67 0L3.97 4.75c-.5.16-.83.61-.83 1.13v17.26c0 2.24.9 4.54 2.7 6.83a27.92 27.92 0 005.63 5.21 50.5 50.5 0 008.05 4.72 1.17 1.17 0 00.96 0 50.5 50.5 0 008.05-4.72 27.93 27.93 0 005.63-5.21c1.8-2.29 2.7-4.59 2.7-6.83V5.88c0-.52-.34-.97-.83-1.12z"
            fill="#42D16D"
          />
          <path
            d="M36.03 4.76L20.33.05A1.17 1.17 0 0020 0v40c.16 0 .33-.03.48-.1a50.5 50.5 0 008.05-4.72 27.92 27.92 0 005.63-5.21c1.8-2.29 2.7-4.59 2.7-6.83V5.88c0-.52-.34-.97-.83-1.12z"
            fill="#3ABB61"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h40v40H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>

  <div class="border" />

  <div>
    <div class="label">
      <Label>In Circulation</Label>
      <Information>
        <Label>
          Approximate value of the total assets in circulation.
          <Bold small>Certified by CertiK.</Bold>
        </Label>
      </Information>
    </div>
    <Number big symbol="usd">{accounting.format(totals.usd, 2)}</Number>
  </div>
</div>
