<style>
  .requestList {
    position: relative;

    display: block;

    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    box-shadow: 0px 0px 100px rgba(39, 68, 247, 0.05);
  }

  .requestList-title {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  .requestList-border {
    position: relative;
    left: 0px;

    width: 100%;
    height: 1px;

    background: #e1e1e1;
  }

  .requestList-request:nth-child(even) {
    background: #f0f0f0;
  }

  .requestList-request.open {
    background: #eef0fe;
  }

  .requestList-header {
    display: none;
  }

  @media (min-width: 1280px) {
    .requestList {
      border-top: none;
      box-shadow: none;
    }

    .requestList-title {
      padding: 20px 28px 40px;
    }

    .requestList-border {
      display: none;
    }

    .requestList-header {
      display: grid;
      grid-gap: 0 28px;
      grid-template-columns: repeat(16, 1fr);
      padding: 4px 28px 0px;

      border: 1px solid #e1e1e1;
    }

    .requestList-request {
      border: 1px solid #e1e1e1;
      border-top: none;
    }

    .requestList-request.open,
    .requestList-request.open:nth-child(even),
    .requestList-request.open:hover,
    .requestList-request.open:hover:nth-child(even) {
      background: #eef0fe;
    }

    .requestList-request:nth-child(even) {
      background: #f9f9f9;
    }

    .requestList-request:hover,
    .requestList-request:hover:nth-child(even) {
      background: #f9f9ff;
    }

    .requestList-amount {
      grid-column: span 4;
    }

    .requestList-time {
      grid-column: span 3;
    }

    .requestList-merchant {
      grid-column: span 3;
    }

    .requestList-status {
      grid-column: span 4;
    }
  }
</style>

<script>
  import { writable } from 'svelte/store';

  import Text from '../Atoms/Text.svelte';
  import Label from '../Atoms/Label.svelte';

  import Request from './Request.svelte';

  export let requests = [];
  export let selectedToken;

  const store = writable();

  let expandedRequest;
  store.subscribe((value) => (expandedRequest = value));

  $: id = ({ type, requestId }) => `${selectedToken.name}-${type}-${requestId}`;
  $: isOpen = (request) => expandedRequest === id(request);

  const toggleExpand = (request) => () => {
    const requestId = id(request);
    if (expandedRequest === requestId) store.set();
    else store.set(requestId);
  };
</script>

<div class="requestList">
  <div class="requestList-title">
    <Text>Transactions</Text>
    &nbsp;
    <Label>({requests.length})</Label>
  </div>

  <div class="requestList-border" />

  <div class="requestList-header">
    <div class="requestList-amount">
      <Label uppercase>Amount ({selectedToken.name})</Label>
    </div>
    <div class="requestList-time">
      <Label uppercase>Date & Time</Label>
    </div>
    <div class="requestList-merchant">
      <Label uppercase>Merchant</Label>
    </div>
    <div class="requestList-status">
      <Label uppercase>Status</Label>
    </div>
  </div>

  {#if requests.length}
    {#each requests as request}
      <div class="requestList-request" class:open={isOpen(request)}>
        <Request
          {request}
          open={isOpen(request)}
          on:click={toggleExpand(request)}
        />
      </div>
    {/each}
  {/if}
</div>
