<style>
  .link {
    position: relative;

    margin: 0;

    color: #2744f7;
    cursor: pointer;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 28px;

    text-decoration: none;
  }

  .link svg {
    display: inline-block;
    width: auto;
  }

  .underline {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;

    background: #2744f7;

    transform: translateY(2px) scale(1);

    transition: transform 0.3s ease-in-out;
  }

  .link:hover .underline {
    transform: translateY(0px) scale(1.1);
  }

  .alternate .underline {
    display: none;
  }

  .external {
    color: #091135;
  }

  @media (min-width: 1280px) {
    .light {
      color: #8bd9f5;
    }

    .light .underline {
      background: #8bd9f5;
    }
  }
</style>

<script>
  export let href;
  export let light = false;
  export let external = false;
  export let alternate = false;
</script>

<a
  {href}
  on:click
  class:light
  class:alternate
  class:external
  class="link"
  target="_blank"
>
  <slot />
  <div class="underline" />
  {#if external}
    &nbsp;
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6 0l.3.1.2.4v4c0 .1 0 .3-.2.4l-.3.1-.4-.1-1.4-1.4-5 5a.2.2 0 01-.4 0l-1-.8v-.2-.2l5.2-5L9.2.8A.5.5 0 019 .5c0-.1 0-.3.2-.4l.3-.1h4zm-3.3 7h.7V9.8c0 .7-.2 1.2-.6 1.6-.5.5-1 .7-1.6.7H2.3c-.7 0-1.2-.2-1.6-.7-.5-.4-.7-1-.7-1.6V3.2c0-.6.2-1.1.7-1.5.4-.5 1-.7 1.6-.7H8V2l-.2.1H2.3c-.4 0-.7.1-1 .4-.2.2-.3.5-.3.8v6.5c0 .4.1.7.4 1 .2.2.5.3.9.3h6.5c.3 0 .6-.1.9-.4.2-.2.3-.5.3-.9V7.2a.2.2 0 01.3-.2z"
        fill="#646464"
      />
    </svg>
  {/if}
</a>
