<style>
  .statusBar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .statusBar-step {
    width: 20px;
    height: 8px;
    margin-right: 4px;

    background: #646464;
    opacity: 0.3;
  }

  .statusBar-step:first-child {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 100px;
  }

  .statusBar-step:last-child {
    margin-right: 12px;

    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .on {
    background: #2744f7;
    opacity: 1;
  }

  .certified {
    background: #42d16d;
    opacity: 1;
  }

  .rejected {
    background: #eb5757;
    opacity: 1;
  }

  @media (min-width: 1280px) {
    .statusBar {
      height: 28px;
    }
  }
</style>

<script>
  export let level = 0;
  export let rejected = false;
  export let certified = false;
</script>

<div class="statusBar">
  <div class="statusBar-step" class:on={level >= 1} />
  <div class="statusBar-step" class:on={level >= 2} />
  <div
    class="statusBar-step"
    class:rejected
    class:certified
    class:on={level >= 3}
  />
</div>
