<style>
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar-links {
    display: none;
  }

  .navbar-menu {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;

    background: none;
    border: none;

    cursor: pointer;
  }

  .navbar-menuBackground {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;

    background: #ffffff;
  }

  .navbar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;

    padding: 28px;
  }

  .navbar-overlayHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
  }

  .navbar-overlay .navbar-links {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .navbar-overlay .navbar-menu {
    display: block;
  }

  .navbar-link {
    margin-bottom: 10px;

    list-style: none;
  }

  .navbar-action {
    margin-top: 84px;

    list-style: none;
  }

  .navbar-overlayFooter {
    margin-top: 56px;

    color: #ffffff;

    font-family: 'Averta Std';
    font-size: 16px;
    line-height: 22px;
  }

  @media (min-width: 1280px) {
    .navbar-menu,
    .navbar-overlay,
    .navbar-overlayHeader,
    .navbar-menuBackground {
      display: none;
    }

    .navbar-links {
      display: block;
    }
  }
</style>

<script>
  import { writable } from 'svelte/store';
  import { fade } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';

  import Logo from '../Atoms/Logo.svelte';
  import Button from '../Atoms/Button.svelte';
  import NavLink from '../Atoms/NavLink.svelte';

  const menuState = writable(false);

  let isOpen;
  menuState.subscribe((value) => {
    isOpen = value;
  });

  const toggleMenu = () => {
    menuState.set(!isOpen);
  };
</script>

<div class="navbar">
  <a href="https://universalprotocol.io/">
    <Logo />
  </a>

  <ul class="navbar-links">
    <NavLink href="https://www.universalprotocol.io/s/whitepaper.pdf">
      Whitepaper
    </NavLink>
    <NavLink isActive href="/">Transparency</NavLink>
    <NavLink href="https://universalprotocol.io/faq">FAQs</NavLink>
    <NavLink isActive href="#subscribe" target="_self">
      <Button>Subscribe</Button>
    </NavLink>
  </ul>

  <button class="navbar-menu" type="button" on:click={toggleMenu}>
    <svg viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8636 2.81058H1.13645C0.508411 2.81058 0 2.36321 0 1.81058C0 1.25795 0.508411 0.810577 1.13645 0.810577H14.8636C15.4916 0.810577 16 1.25795 16 1.81058C16 2.36321 15.4916 2.81058 14.8636 2.81058Z"
        fill="white"
      />
      <path
        d="M21.8076 12.8106H1.19236C0.533424 12.8106 0 12.3632 0 11.8106C0 11.2579 0.533424 10.8106 1.19236 10.8106H21.8076C22.4666 10.8106 23 11.2579 23 11.8106C23 12.3632 22.4666 12.8106 21.8076 12.8106Z"
        fill="white"
      />
      <path
        d="M14.8636 23.8106H1.13645C0.508411 23.8106 0 23.3632 0 22.8106C0 22.2579 0.508411 21.8106 1.13645 21.8106H14.8636C15.4916 21.8106 16 22.2579 16 22.8106C16 23.3632 15.4916 23.8106 14.8636 23.8106Z"
        fill="white"
      />
    </svg>
  </button>

  {#if isOpen}
    <svg
      class="navbar-menuBackground"
      viewBox="0 0 383 1092"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transition:fade={{ duration: 200, easing: quintOut }}
    >
      <path
        d="M-86.38 40.19C-58.56-63.75 18.97-164.47 117.24-229.26l789.81-456.51c105.18-52.8 221.64-72.2 334.9-41.82l632.34 169.63c113.26 30.38 182.35 150.19 152 263.57l-169.43 633.06c-30.35 113.38-107.89 214.11-203.62 269.45l-789.82 456.51c-105.17 52.81-221.63 72.2-334.9 41.82l-632.34-169.63c-113.25-30.38-182.34-150.19-152-263.57L-86.38 40.19z"
        fill="#8BD9F5"
      />
      <path
        d="M-15.85 41.4C11.97-62.44 89.51-163.05 187.77-227.76l789.82-456.01c105.17-52.75 221.63-72.12 334.89-41.77l632.34 169.44c113.26 30.35 182.35 150.02 152 263.28l-169.43 632.35c-30.35 113.26-107.89 213.87-203.62 269.15l-789.82 456c-105.17 52.75-221.63 72.12-334.89 41.77L-33.28 937.02c-113.26-30.34-182.35-150.01-152-263.27L-15.85 41.39z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="-192.44"
          y1="-739.93"
          x2="-192.44"
          y2="1120.86"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#276CF7" />
          <stop offset="1" stop-color="#2744F7" />
        </linearGradient>
      </defs>
    </svg>

    <div
      class="navbar-overlay"
      transition:fade={{ duration: 200, easing: quintOut }}
    >
      <div class="navbar-overlayHeader">
        <Logo />
        <button class="navbar-menu" type="button" on:click={toggleMenu}>
          <svg
            viewBox="0 0 18 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.83 2.26L2.26 16.83c-.47.47-1.16.53-1.55.14-.4-.39-.33-1.08.13-1.55L15.42.84c.47-.46 1.16-.52 1.55-.13.4.39.33 1.08-.14 1.55z"
              fill="#fff"
            />
            <path
              d="M15.42 16.83L.84 2.26C.38 1.79.32 1.1.71.7 1.1.3 1.79.38 2.26.84l14.57 14.58c.47.47.53 1.16.14 1.55-.39.4-1.08.33-1.55-.14zM15.86 24.84H2.14c-.63 0-1.14-.45-1.14-1s.5-1 1.14-1h13.72c.63 0 1.14.45 1.14 1s-.5 1-1.14 1z"
              fill="#fff"
            />
          </svg>
        </button>
      </div>

      <ul class="navbar-links">
        <li class="navbar-link">
          <NavLink href="https://www.universalprotocol.io/s/whitepaper.pdf">
            Whitepaper
          </NavLink>
        </li>
        <li class="navbar-link">
          <NavLink isActive href="/">Transparency</NavLink>
        </li>
        <li class="navbar-link">
          <NavLink href="https://universalprotocol.io/faq">FAQs</NavLink>
        </li>
        <li class="navbar-action">
          <Button>Subscribe</Button>
        </li>
      </ul>

      <div class="navbar-overlayFooter">
        © 2020 Universal Protocol Token.
        <br />
        All Rights Reserved
      </div>
    </div>
  {/if}
</div>
