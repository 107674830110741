<style>
  .grid {
    display: grid;
    grid-gap: 0 26px;
    grid-template-columns: repeat(5, minmax(43px, 1fr));

    max-width: 100%;
  }

  .center {
    align-items: center;
  }

  .debug * {
    background: rgba(255, 0, 0, 0.3);
  }

  @media (min-width: 1280px) {
    .grid {
      grid-gap: 0 28px;
      grid-template-columns: repeat(20, minmax(43px, 1fr));
    }
  }
</style>

<script>
  import { range } from 'lodash';
  export let debug = false;
  export let center = false;
</script>

<div class="grid" class:center class:debug>
  <slot>
    {#each range(5) as column}
      <div>{column + 1}</div>
    {/each}
  </slot>
</div>
