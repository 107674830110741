<style>
  .assets {
    position: relative;
  }

  .assets-header {
    margin-bottom: 84px;

    text-align: center;
  }

  .assets-headerDropdown {
    position: relative;
    top: -50px;

    z-index: 1;

    width: 100%;

    text-align: center;
  }

  .assets-overview {
    display: flex;
  }

  .assets-overview div:not(:last-child) {
    margin-right: 34px;
  }

  .assets-lastCertification {
    position: relative;

    margin-top: -50px;
    margin-bottom: 14px;

    text-align: center;
  }

  .assets-transactions {
    margin-top: 32px;
  }

  @media (min-width: 1280px) {
    .assets-overview {
      justify-content: center;
    }

    .assets-headerDropdown {
      position: absolute;
      top: 0;
      left: 50%;

      z-index: 1;

      transform: translate(-50%, -50%);
    }

    .assets-headerDropdown.open {
      top: -25%;
      left: 0%;
      z-index: 3;

      transform: none;
    }

    .assets-lastCertification {
      z-index: 2;

      margin-top: 20px;
      margin-bottom: 30px;
    }

    .assets-transactions {
      margin-top: 0px;
    }
  }
</style>

<script>
  import accounting from 'accounting';
  import { writable } from 'svelte/store';

  import { queryRequests } from '../../api/index.svelte';

  import Bold from '../Atoms/Bold.svelte';
  import Card from '../Atoms/Card.svelte';
  import Link from '../Atoms/Link.svelte';
  import Text from '../Atoms/Text.svelte';
  import Label from '../Atoms/Label.svelte';
  import Arrow from '../Atoms/Arrow.svelte';
  import Number from '../Atoms/Number.svelte';
  import Subtitle from '../Atoms/Subtitle.svelte';

  import Dropdown from './Dropdown.svelte';
  import RequestList from './RequestList.svelte';
  import CertikBanner from './CertikBanner.svelte';

  let open = false;
  export let totals;
  export let tokens;
  export let requests;
  export let selectedToken;

  const selectedTokenStore = writable(selectedToken);

  const setSelectedToken = (token) => selectedTokenStore.set(token);

  selectedTokenStore.subscribe((value) => {
    selectedToken = value;
  });

  $: ({
    name,
    contractAddress,
    latestState: { supply, reservePercentage },
    latestCertification,
  } = selectedToken);

  $: queryRequests(name);
</script>

<div class="assets">
  <div class="assets-header">
    <Subtitle>Assets in detail</Subtitle>
  </div>

  <Card>
    <div slot="header">
      <div class="assets-headerDropdown" class:open>
        <Dropdown {tokens} {selectedToken} {setSelectedToken} bind:open />
      </div>
      <div class="assets-lastCertification">
        <CertikBanner
          alternate
          latestCertification={{ tokenName: name, timestamp: latestCertification }}
          tokenContract="https://etherscan.io/address/{contractAddress}"
        />
      </div>
    </div>

    <div slot="content" class="assets-overview">
      <div>
        <Label>Covered</Label>
        <Number positive>{accounting.format(reservePercentage)}%</Number>
      </div>
      <div>
        <Label>In circulation</Label>
        <Number symbol={name}>
          {accounting.format(supply, 8).replace(/\.?0+$/, '')}
        </Number>
      </div>
    </div>
  </Card>

  <div class="assets-transactions">
    <RequestList {selectedToken} {requests} />
  </div>
</div>
