<style>
  div.coinLogo svg {
    display: inline-block;
    width: 100%;
  }
</style>

<script>
  import UPBTC from './Logos/UPBTC.svg';
  import UPUSD from './Logos/UPUSD.svg';
  import UPEUR from './Logos/UPEUR.svg';
  import UPXAU from './Logos/UPXAU.svg';

  export const LOGOS = {
    UPBTC,
    UPUSD,
    UPEUR,
    UPXAU,
  };

  export let coin = Object.keys(LOGOS)[0];
  export let icon = LOGOS[coin.toUpperCase()];
</script>

<div class="coinLogo">
  {@html icon}
</div>
