<style>
  .bold {
    display: inline-block;
    margin: 0;

    color: #091135;

    font-family: 'Averta Std';
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .alternate {
    color: #2744f7;
  }

  @media (min-width: 1280px) {
    .bold {
      font-size: 20px;
      line-height: 28px;
    }

    .small {
      font-size: 16px;
      line-height: 28px;
    }
  }
</style>

<script>
  export let small = false;
  export let alternate = false;
</script>

<p class="bold" class:small class:alternate>
  <slot>CertiK</slot>
</p>
