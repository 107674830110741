<style>
  .request {
    position: relative;

    width: 100%;
  }

  .request-content {
    position: relative;

    width: 100%;
    padding: 16px 20px;

    background: none;
    border: none;

    cursor: pointer;
    outline: none;
  }

  .request-cell {
    display: flex;
  }

  .request-type {
    margin-left: 20px;
  }

  .request-status {
    display: flex;
    margin-top: 15px;
  }

  .request-merchant {
    display: none;
  }

  .request-detailsLink {
    position: absolute;
    top: 50%;
    right: 20px;

    transform: translate(0%, -50%);
  }

  .request-detailsLabel {
    display: none;
  }

  @media (min-width: 1280px) {
    .request-content {
      display: grid;
      grid-gap: 0 28px;
      grid-template-columns: repeat(16, 1fr);
      padding: 15px 28px;
    }

    .request-cell {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .request-merchant {
      display: flex;
    }

    .request-label {
      display: none;
    }

    .request-type {
      margin-left: 0;
    }

    .request-amount {
      grid-column: span 4;
    }

    .request-time {
      grid-column: span 3;
    }

    .request-merchant {
      grid-column: span 3;
    }

    .request-status {
      grid-column: span 4;
      margin: 0;
    }

    .request-detailsLink {
      top: 10px;
      right: 28px;

      display: flex;
      flex-direction: row;
      align-items: center;

      transform: none;
    }

    .request-details {
      display: block;

      padding: 0;
      margin: 0;
    }

    .request-detailsLabel {
      display: block;
    }
  }
</style>

<script>
  import moment from 'moment';
  import accounting from 'accounting';
  import { slide } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';

  import { EVENTS } from '../../api/index.svelte';

  import Bold from '../Atoms/Bold.svelte';
  import Label from '../Atoms/Label.svelte';
  import Arrow from '../Atoms/Arrow.svelte';

  import StatusBar from './StatusBar.svelte';
  import RequestType from './RequestType.svelte';
  import RequestState from './RequestState.svelte';

  export let request;
  export let open = false;

  $: ({
    type,
    events,
    merchant,
    requestId,
    tokenName,
    requestDetails: { amount },
    stateAfterFulfill,
  } = request);

  $: level = events.length;
  $: lastEvent = events[0];
  $: certified = EVENTS[lastEvent.name] === 'Certified';
</script>

<div class="request">
  <button on:click type="button" class="request-content">
    <div class="request-cell request-amount">
      <div class="request-label">
        <Label>Amount: &nbsp;</Label>
      </div>
      <Bold small>
        {accounting.formatNumber(amount, 8).replace(/\.?0+$/, '')}
      </Bold>
      <div class="request-type">
        <RequestType {type} />
      </div>
    </div>
    <div class="request-cell request-time">
      <div class="request-label">
        <Label>Time: &nbsp;</Label>
      </div>
      <Label alternate>
        {moment(lastEvent.timestamp, 'X').format('HH:mm - MMM D YYYY')}
      </Label>
    </div>
    <div class="request-cell request-merchant">
      <Label alternate>{merchant.name}</Label>
    </div>
    <div class="request-cell request-status">
      <StatusBar {level} {certified} rejected={!certified} />
      <Label>{EVENTS[lastEvent.name]}</Label>
    </div>
    <div class="request-detailsLink">
      <div class="request-detailsLabel">
        <Label alternate>Details &nbsp;</Label>
      </div>
      <Arrow up={open} />
    </div>
  </button>

  {#if open}
    <div
      class="request-cell request-details"
      transition:slide={{ duration: 200, easing: quintOut }}
    >
      <RequestState {tokenName} {requestId} {events} {stateAfterFulfill} />
    </div>
  {/if}
</div>
